import React from "react";
import "./Button.css";

type ButtonProps = {
  type: "primary" | "secondary";
  content?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
  className?: string;
  size?: "sm" | "md" | "lg";
};

const Button = ({
  type,
  content,
  onClick,
  size,
  children,
  disabled,
  className,
}: ButtonProps) => {
  return (
    <button
      disabled={disabled}
      type="submit"
      className={`btn Button Button-${type} ${size ? `btn-${size}` : ""} ${
        className ? className : ""
      }`}
      onClick={onClick}
    >
      {content || children || ""}
    </button>
  );
};

export default Button;
