import "./Badge.css";

type BadgeProps = {
  children: React.ReactNode;
  className?: string;
  type?: "primary" | "added" | "removed";
} & JSX.IntrinsicElements["span"];

const Badge = ({ children, className, type, ...rest }: BadgeProps) => {
  type = type || "primary";
  return (
    <span className={`badge rounded-pill ${className} Badge-${type}`} {...rest}>
      {children}
    </span>
  );
};

export default Badge;
