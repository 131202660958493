import React from "react";

interface TextareaProps {
  id: string;
  label: string;
  value?: string;
  disabled?: boolean;
  required?: boolean;
  onChange: (value: string) => void;
  rows?: number;
  placeholder?: string;
}

const Textarea: React.FC<TextareaProps> = ({
  id,
  label,
  value,
  onChange,
  disabled = false,
  required = false,
  rows = 3,
  placeholder,
}) => {
  return (
    <div className="mb-3">
      <label htmlFor={id} className="form-label">
        {label}
        {required && <span className="text-danger">*</span>}
      </label>
      <textarea
        id={id}
        className="form-control"
        rows={rows}
        placeholder={placeholder}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        disabled={disabled}
      />
    </div>
  );
};

export default Textarea;
