import React from "react";
interface SelectOption {
  value: string;
  label: string;
}

interface GroupedSelectOption {
  label: string;
  options: SelectOption[];
}

interface SelectProps {
  id: string;
  label: string;
  options: SelectOption[] | GroupedSelectOption[];
  value: string;
  isLoading?: boolean;
  disabled?: boolean;
  required?: boolean;
  onChange: (value: string, label?:string) => void;
  placeholder?: string;
  className?: string;
}

const Select: React.FC<SelectProps> = ({
  options,
  value,
  onChange,
  label,
  className,
  id,
  isLoading,
  required,
  placeholder,
  disabled,
}) => {
  const optionsElement = options.map((option) => {
    if ((option as GroupedSelectOption).options) {
      const group = option as GroupedSelectOption;
      return (
        <optgroup key={group.label} label={group.label}>
          {group.options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </optgroup>
      );
    }

    return (
      <option
        key={(option as SelectOption).value}
        value={(option as SelectOption).value}
      >
        {option.label}
      </option>
    );
  });

  return (
    <div className="">
      <label htmlFor={id} className="form-label">
        {label}
        {required && <span className="text-danger">*</span>}
      </label>
      <div className="input-group ">
        <select
          disabled={disabled}
          className={`form-select ${className || ''}`}
          value={value}
          onChange={(event) => onChange(event.target.value, event.target.options[event.target.selectedIndex].text)}
        >
          {placeholder && (
            <option key={Math.random()} value="" disabled>
              {placeholder}
            </option>
          )}
          {optionsElement}
        </select>
        {isLoading && (
          <div className=" input-group-text">
            <div className="spinner-border spinner-border-sm text-secondary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Select;
