import "./Loader.css";

type LoaderProps = {
  size?: "small" | "medium" | "large";
};

const sizes = {
  small: {
    outer: "1.5rem",
    middle: ".5rem",
    inner: ".25rem",
  },
  medium: {
    outer: "2.5rem",
    middle: "1.5rem",
    inner: ".5rem",
  },
  large: {
    outer: "3.5rem",
    middle: "2.5rem",
    inner: "1.5rem",
  },
};
const Loader = ({ size = "large" }: LoaderProps) => {
  const { inner, middle, outer } = sizes[size];

  return (
    <div className={"text-center spinner-container"}>
      <div
        className={"spinner spinner-slow aei-spinner-border"}
        style={{ width: outer, height: outer }}
        role="status"
      >
        <span className={"visually-hidden"}>Loading...</span>
      </div>
      <div
        className={"spinner spinner-fast aei-spinner-border reverse"}
        style={{ width: middle, height: middle }}
        role="status"
      ></div>
      <div
        className={"spinner spinner-slower aei-spinner-border reverse"}
        style={{ width: inner, height: inner }}
        role="status"
      ></div>
    </div>
  );
};

export default Loader;
