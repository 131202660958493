const FieldErrorMsg = ({ errors }: { errors: Array<string> }) => {
  return errors.length ? (
    <div className="col-12 mt-2">
      <p className="alert alert-danger mb-0">{errors.join(", ")}</p>
    </div>
  ) : (
    <></>
  );
};

export default FieldErrorMsg;
