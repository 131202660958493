import React from "react";

type ContentContainerWithSidebarProps = {
  children: React.ReactNode;
};

const ContentContainerWithSidebar: React.FC<
  ContentContainerWithSidebarProps
> = ({ children }) => {
  return (
    <div className="content-container with-sidebar">
      <div className="container-fluid">{children}</div>
    </div>
  );
};

export default ContentContainerWithSidebar;
