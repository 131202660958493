import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./Datepicker.css";
interface DatepickerProps {
  label?: string;
  name?: string;
  date?: Date;
  onChange?: (date: Date) => void;
}

const Datepicker = ({ label, name, date, onChange }: DatepickerProps) => {
  return (
    <div className="form-group">
      {label && (
        <label htmlFor={name} className="mb-2">
          {label}
        </label>
      )}

      <div>
        <DatePicker
          dateFormat={"dd.MM.yyyy"}
          id={name}
          selected={date || new Date()}
          onChange={(date: Date) => {
            onChange && onChange(date);
          }}
          className="form-control"
        />
      </div>
    </div>
  );
};

export default Datepicker;
