import { Fragment } from "react";

type IconLabelProps = {
  type?: "bi" | "fa";
  name: string;
  content?: string | number | JSX.Element;
  sz?: 1 | 2 | 3 | 4 | 5 | 6;
  
};

const IconLabel = ({ type, name, content, sz }: IconLabelProps) => {
  type = type ? type : "bi";
  const iconStyle = `${type} ${type}-${name}`;
  const sizeStyle = sz ? `fs-${sz}` : "";
  return (
    <Fragment>
      <i className={`${iconStyle} me-1 ${sizeStyle}`}></i>
      {content}
    </Fragment>
  );
};

export default IconLabel;
