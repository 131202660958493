import "./Pagination.css";

import { PaginationMetaT } from "../../../api/types";

function generatePaginationArray(
  paginationInfo: PaginationMetaT
): (number | string)[] {
  const { current_page, last_page, total, per_page } = paginationInfo;
  const paginationArray: (number | "...")[] = [];

  // Calculate the total number of pages
  const totalPages = Math.min(last_page, Math.ceil(total / per_page));

  if (totalPages <= 6) {
    for (let i = 1; i <= totalPages; i++) {
      paginationArray.push(i);
    }
  } else {
    if (current_page <= 4) {
      // console.log(current_page);
      for (let i = 1; i <= 5; i++) {
        paginationArray.push(i);
      }
      paginationArray.push("...");
      paginationArray.push(totalPages - 1);
      paginationArray.push(totalPages);
    } else if (current_page >= totalPages - 2) {
      paginationArray.push(1);
      paginationArray.push(2);
      paginationArray.push("...");
      for (let i = totalPages - 3; i <= totalPages; i++) {
        paginationArray.push(i);
      }
    } else {
      paginationArray.push(1);
      paginationArray.push(2);
      paginationArray.push("...");
      for (let i = current_page - 1; i <= current_page + 1; i++) {
        paginationArray.push(i);
      }

      if (current_page + 1 !== totalPages - 2) paginationArray.push("...");

      paginationArray.push(totalPages - 1);
      paginationArray.push(totalPages);
    }
  }

  return paginationArray;
}
type PaginationProps = {
  pagination: PaginationMetaT | null;
  onChange: (page: number) => void;
  centered?: boolean;
};
const Pagination = ({ pagination, onChange, centered }: PaginationProps) => {
  return pagination ? (
    <div className="row">
      <div
        className={`col-12 ${centered ? "justify-content-center d-flex" : ""}`}
      >
        <nav aria-label="page navigation">
          <ul className="pagination Pagination-themed">
            <li className="page-item">
              <a
                className={`page-link ${
                  pagination.current_page === 1 ? "disabled" : ""
                }`}
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  if (pagination.current_page !== 1) {
                    onChange(pagination.current_page - 1);
                  }
                }}
                aria-label="Предишна страница"
              >
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            {generatePaginationArray(pagination).map((page, index) => (
              <li className="page-item" key={`${page}-${index}`}>
                <a
                  className={`page-link ${
                    pagination.current_page === page ? "active" : ""
                  }`}
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    if (typeof page === "number") onChange(page);
                  }}
                >
                  {page}
                </a>
              </li>
            ))}
            <li className="page-item">
              <a
                className={`page-link ${
                  pagination.current_page === pagination.last_page
                    ? "disabled"
                    : ""
                }`}
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  if (pagination.current_page !== pagination.last_page) {
                    onChange(pagination.current_page + 1);
                  }
                }}
                aria-label="Следваща страница"
              >
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Pagination;
