import { useEffect } from "react";
import { Tooltip as BSTooltip } from "bootstrap";

import "./BasicTooltip.css";
import useAuthContext from "../../../context/authentication";

interface BasicTooltipProps {
  id: string;
  tip: string;
  text?: string;
  clean?: boolean;
  children?: React.ReactNode;
}

const BasicTooltip = ({
  tip,
  id,
  text,
  clean,
  children,
  ...props
}: BasicTooltipProps) => {
  const { nightMode } = useAuthContext();

  useEffect(() => {
    const el = document.querySelector(`#${id}[data-bs-toggle="tooltip"]`);
    if (el) {
      new BSTooltip(el, {
        customClass: nightMode ? "Basic-tooltip-md Basic-tooltip-dark" : "Basic-tooltip-md Basic-tooltip-light",
      });
    }
  }, [nightMode]);

  return (
    <span
      style={{ cursor: "default" }}
      className={`${clean ? "" : "Basic-tooltip"}`}
      data-bs-toggle="tooltip"
      id={id}
      title={tip}
      {...props}
    >
      {children || text || ""}
    </span>
  );
};
export default BasicTooltip;
// export default {};
