import React, { InputHTMLAttributes, useEffect, useRef } from "react";
import "./Input.css";
import Icon from "../Icon/Icon";

type CustomHTMLInputElement = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "onChange"
>;

interface InputProps extends CustomHTMLInputElement {
  label?: string;
  name: string;
  type?: string;
  noReset?: boolean;
  placeholder?: string;
  sizetype?: "sm" | "md" | "lg";
  onChange?: (value: string | number) => void;
  value?: string | number;
}

const Input = ({
  name,
  type,
  label,
  value,
  noReset,
  onChange,
  sizetype = "md",
  placeholder,
  ...rest
}: InputProps) => {
  const [inputType, setInputType] = React.useState<string>("text");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (type) {
      setInputType(() => type);
    }
  }, [type]);
  /**
   * Converts the password field to visible text and vice versa
   */
  const togglePassword = () => {
    if (inputType === "password") {
      setInputType(() => "text");
      return;
    }
    setInputType(() => "password");
  };

  const clearInput = () => {
    onChange && onChange("");
  };

  return (
    <div className="form-group">
      {label ? (
        <label htmlFor={name} className="form-label">
          {label}
        </label>
      ) : (
        <></>
      )}
      <div className="input-group">
        <input
          type={inputType}
          className={`form-control form-control-${sizetype}`}
          id={name}
          name={name}
          ref={inputRef}
          placeholder={placeholder}
          onChange={(e: any) => {
            onChange && onChange(e.target.value);
          }}
          {...rest}
          value={value}
        />
        {noReset ? (
          <></>
        ) : (
          <button
            className="btn btn-outline-accent"
            onClick={clearInput}
            type="button"
          >
            <Icon name="x-lg" />
          </button>
        )}
        {type === "password" && (
          <button
            className="btn btn-outline-accent"
            onClick={togglePassword}
            type="button"
          >
            <Icon name={inputType === "password" ? "eye-fill" : "eye-slash"} />
          </button>
        )}
      </div>
    </div>
  );
};

export default Input;
