import Loader from "../Loader/Loader";
import styles from "./OverlayLoader.module.css"; // Import CSS module

type OverlayLoaderProps = {
  size?: "small" | "medium" | "large";
  message?: string;
};

const OverlayLoader = ({ size = "large", message }: OverlayLoaderProps) => {
  return (
    <div
      className={`text-center d-flex flex-column justify-content-center align-items-center ${styles["overlay"]}`}
    >
      <div className="">
        <Loader size={size} />
      </div>

      {message && (
        <div className="">
          <h5 className="mt-5">{message}</h5>
        </div>
      )}
    </div>
  );
};

export default OverlayLoader;
