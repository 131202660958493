type IconProps = {
  type?: "bi" | "fa";
  name: string;
  sz?: 1 | 2 | 3 | 4 | 5 | 6;
  className?: string;
};

const Icon = ({ type, name, sz, className = "" }: IconProps) => {
  type = type ? type : "bi";
  const iconStyle = `${type} ${type}-${name}`;
  const sizeStyle = sz ? `fs-${sz}` : "";
  return <i className={`${iconStyle} ${sizeStyle} ${className}`}></i>;
};

export default Icon;
