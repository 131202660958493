import React, { lazy, Suspense } from "react";
import { Loader } from "../components/common";

const componentMap: Record<string, React.LazyExoticComponent<React.FC<any>>> = {
  Login: lazy(() => import("../pages/Login/Login")),
  Register: lazy(() => import("../pages/Register/Register")),
  RequestResetPassword: lazy(() => import("../pages/ForgotPassword/RequestResetPassword")),
  ResetPassword: lazy(() => import("../pages/ForgotPassword/ResetPassword")),
  OZKLoginPage: lazy(() => import("../pages/VIP/OZK/Login/Login")),
  OZKEvaluationPage: lazy(() => import("../pages/VIP/OZK/Evaluation/Evaluation")),
  PorscheLoginPage: lazy(() => import("../pages/VIP/Porsche/Login/Login")),
  AutoExpertNewDemoLanding: lazy(() => import("../pages/AutoExpertNewDemoLanding/AutoExpertNewDemoLanding")),
  AutoExpertMarketPrice: lazy(() => import("../pages/AutoExpertMarketPrice/AutoExpertMartketPrice")),
  AutoExpertInsValue: lazy(() => import("../pages/AutoExpertInsValue/AutoExpertInsValue")),
  AutoExpertTechData: lazy(() => import("../pages/AutoExpertTechData/AutoExpertTechData")),
  AutoExpertVIN: lazy(() => import("../pages/AutoExpertVIN/AutoExpertVIN")),
  AutoExpertNew: lazy(() => import("../pages/AutoExpertNew/AutoExpertNew")),
  AutoExpertNewDemo: lazy(() => import("../pages/AutoExpertNewDemo/AutoExpertNewDemo")),
  AutoExpertEasy: lazy(() => import("../pages/AutoExpertEasy/AutoExpertEasy")),
  AutoExpertEasyEdit: lazy(() => import("../pages/AutoExpertEasyEdit/AutoExpertEasyEdit")),
  ProfileSettings: lazy(() => import("../pages/Profile/Settings/Settings")),
  ProfileHistory: lazy(() => import("../pages/Profile/History/History")),
  Applications: lazy(() => import("../pages/Shared/Applications/Applications")),
  ApplicationEdit: lazy(() => import("../pages/Shared/ApplicationEdit/ApplicationEdit")),
  CompanySettings: lazy(() => import("../pages/Company/Settings/Settings")),
  CompanyUsers: lazy(() => import("../pages/Company/Users/Users")),
  CompanyHistory: lazy(() => import("../pages/Company/History/History")),
  CompanyUserDetails: lazy(() => import("../pages/Company/UserDetails/UserDetails")),
  AutoExpertTechDataLanding: lazy(() => import("../pages/AutoExpertTechDataLanding/AutoExpertTechDataLanding")),
  AutoExpertInsValueLanding: lazy(() => import("../pages/AutoExpertInsValueLanding/AutoExpertInsValueLanding")),
  AutoExpertMarketPriceLanding: lazy(() => import("../pages/AutoExpertMarketPriceLanding/AutoExpertMarketPriceLanding")),
  AutoExpertEasyLanding: lazy(() => import("../pages/AutoExpertEasyLanding/AutoExpertEasyLanding")),
  AutoExpertVINLanding: lazy(() => import("../pages/AutoExpertVINLanding/AutoExpertVINLanding")),
  NotFound: lazy(() => import("../pages/errors/NotFound/NotFound")),
  EmailVerification: lazy(() => import("../pages/EmailVerification/EmailVerification")),
  ProfileLayout: lazy(() => import("../layouts/ProfileLayout")),
  AboutUs: lazy(() => import("../pages/AboutUs/AboutUs")),
  Home: lazy(() => import("../pages/Home/Home")),
  AdministrationLayout: lazy(() => import("../layouts/AdministrationLayout")),
  ApplicationsLayout: lazy(() => import("../layouts/ApplicationsLayout")),
  LandingLayout:  lazy(() => import("../layouts/LandingLayout")),
  AutoExpertNewLanding: lazy(() => import("../pages/AutoExpertNewLanding/AutoExpertNewLanding")),
};

const LazyComponentLoader: React.FC<{
  componentName: keyof typeof componentMap;
  params?: Record<string, any>;
}> = ({ componentName, params }) => {
  const Component = componentMap[componentName];
  return (
    <Suspense fallback={<Loader />}>
      <Component {...params} />
    </Suspense>
  );
};

export default LazyComponentLoader;
