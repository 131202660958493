import styles from "./Separator.module.css";
interface SeparatorProps {
  tickness?: number;
  shadow?: boolean;
  primaryColored?: boolean;
}
const Separator = ({ tickness, shadow, primaryColored }: SeparatorProps) => {
  return (
    <hr
      className={`${shadow ? styles[`shadow`] : ""} ${
        primaryColored ? styles[`primary`] : ""
      }  ${styles["Separator"]} ${
        styles[`Separator-tickness-${tickness || 1}`]
      }`}
    />
  );
};
export default Separator;
