import "./FixedFooter.css";

const FixedFooter = (props: any) => {
  return (
    <div className="position-fixed FixedFooter-container Theme-dark">
      <div className={`${props.isFluid ? "container-fluid" : "container"}`}>
        {props.children}
      </div>
    </div>
  );
};

export default FixedFooter;
