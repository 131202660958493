type AlertProps = {
  type: "danger" | "warning" | "success" | "info";
  children: React.ReactNode;
};

const Alert = ({ type, children }: AlertProps) => {
  return (
    <div className={`alert alert-${type}`} role="alert">
      {children}
    </div>
  );
};

export default Alert;
