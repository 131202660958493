import { useEffect, useState } from "react";
import "./Switch.css";

interface SwitchProps {
  checked: boolean;
  onChange: (e: any) => void;
  children?: React.ReactNode;
  id?: string;
}

const Switch = ({ checked, onChange, children, id }: SwitchProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  useEffect(() => {
    setIsChecked(() => checked || false);
  }, [checked]);

  return (
    <div className="form-check form-switch">
      <input
        className="form-check-input AEI-toggle"
        type="checkbox"
        role="switch"
        id={id}
        checked={isChecked}
        onChange={(e) => {
          setIsChecked(() => e.target.checked);
          onChange(e);
        }}
      />
      <label className="form-check-label" htmlFor={id}>
        {children}
      </label>
    </div>
  );
};

export default Switch;
