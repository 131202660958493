import "./App.css";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

const App = () => {
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      const handleContextmenu = (e: any) => {
        e.preventDefault();
      };
      document.addEventListener("contextmenu", handleContextmenu);
      return function cleanup() {
        document.removeEventListener("contextmenu", handleContextmenu);
      };
    }
  }, []);

  return <Outlet />;
};

export default App;
