import { ChangeEvent, InputHTMLAttributes } from "react";

interface ImageUpload extends InputHTMLAttributes<HTMLInputElement> {
  onImagesSelected: (files: FileList | null) => void;
  onErrorImageSelect?: (message: string) => void;
  maxSize?: number;
}

const ImageUploadInput = ({
  onImagesSelected,
  onErrorImageSelect,
  maxSize,
  ...rest
}: ImageUpload) => {
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (maxSize && files) {
      const erroredFiles: string[] = [];
      const maxSizeBytes = maxSize * 1024 * 1024;

      // Filter files based on the maxSize
      const filteredFiles = Array.from(files).filter((file) => {
        if (file.size <= maxSizeBytes) {
          return true;
        }
        erroredFiles.push(file.name);
        return false;
      });

      // Create a new FileList from the filtered files
      const newFileList = new DataTransfer();
      for (const file of filteredFiles) {
        newFileList.items.add(file);
      }
      if (erroredFiles.length > 0) {
        onErrorImageSelect &&
          onErrorImageSelect(
            `Следните файлове са по-големи от ${maxSize}MB: ${erroredFiles.join(
              ", "
            )}`
          );
      }

      // Set the input's files to the new FileList
      event.target.files = newFileList.files;
    }
    onImagesSelected(event.target.files);

    event.target.files = new DataTransfer().files;
  };

  return (
    <div>
      <input
        type="file"
        className="form-control"
        {...rest}
        onChange={handleFileChange}
      />
    </div>
  );
};

export default ImageUploadInput;
