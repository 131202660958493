import { createContext, useContext, useEffect, useState } from "react";
import {
  authenticateUser,
  authenticateUserWithSocial,
  createAccount,
  fetchUserBalance,
  getCompanyData,
  getCSRFCookie,
  getUser,
  logoutUser,
  setAuthHeader,
  updateUserProfile,
} from "../api/main";
import { useLocation, useNavigate } from "react-router-dom";
import { getIsExperimental } from "../utils/env";

/**
 * User type
 */
interface User {
  firstName: string;
  lastName: string;
  email: string;
  email_verified_at?: string;
  is_demo: boolean;
  license: boolean;
  phone?: string;
  role: {
    name: string;
  };
  additional_information: {
    [key: string]: string;
  };
}

/**
 * User login data type
 */
interface UserLoginData {
  email: string;
  password: string;
  rememberMe: boolean;
  vipClient?: "ozk" | "vig";
}
type UserRegisterData = Omit<UserLoginData, "rememberMe"> & {
  firstName: string;
  lastName: string;
  phone?: string;
  confirmPassword: string;
};

export type UserEditData = {
  firstName: string;
  lastName: string;
  password?: string;
  phone?: string;
  confirmPassword?: string;
};
export type CompanyUserEditData = Omit<UserEditData, "confirmPassword"> & {
  email: string;
  role: {
    id: string | number;
    name: string;
  };
  branch?: string;
  office?: string;
  isActive: boolean;
};

export type Company = {
  name: string;
  balance: number;
  maxActiveUsers: number;
  activeUsersCount: number;
  isIndividual: boolean;
  configurations?: {
    pdfBannerPath?: string | null;
  };
};
/**
 * Authentication context type
 */
interface AuthContextType {
  user: User | null;
  userBalance: number | null;
  company: Company | null;
  nightMode: boolean;
  getUserBalance: () => void;
  login: (userData: UserLoginData, redirectTo?: string | null) => void;
  register: (userData: UserRegisterData, redirectTo?: string | null) => void;
  editProfile: (userData: UserEditData) => any;
  socialLogin: (
    provider: "google",
    payload: any,
    redirectTo?: string | null
  ) => void;
  logout: () => void;
  errors?: { [key: string]: string[] } | null;
  isLoading: boolean;
  refreshCompanyData: () => void;
  configureNightMode: (value: boolean) => void;
}

/**
 * Authentication context
 *
 * @type {React.Context<AuthContextType>}
 * @see https://reactjs.org/docs/context.html
 * @see https://reactjs.org/docs/hooks-reference.html#usecontext
 * @see https://reactjs.org/docs/hooks-custom.html
 * @see https://reactjs.org/docs/hooks-rules.html
 * @see https://reactjs.org/docs/hooks-faq.html#should-i-use-one-or-many-state-variables
 */
export const AuthContext = createContext<AuthContextType>({
  user: null,
  nightMode: false,
  userBalance: null,
  login: () => {},
  socialLogin: () => {},
  register: () => {},
  logout: () => {},
  getUserBalance: () => {},
  isLoading: false,
  errors: null,
  company: null,
  configureNightMode: () => {},
  editProfile: () => {},
  refreshCompanyData: () => {},
});

/**
 * Authentication provider
 *
 * @param param0
 * @returns {JSX.Element} The authentication provider
 */
export const AuthProvider: React.FC<any> = ({ children }: any): JSX.Element => {
  const [nightMode, setNightMode] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [company, setCompany] = useState<Company | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<{ [key: string]: string[] } | null>(
    null
  );
  const [userBalance, setUserBalance] = useState<number | null>(null);
  const navigate = useNavigate();

  // hack to clear errors on location change
  const location = useLocation();
  useEffect(() => {
    setErrors(() => null);
  }, [location]);

  const getUserBalance = async (): Promise<void> => {
    try {
      const response = await fetchUserBalance();
      setUserBalance(() => response.balance);
    } catch {
      setUserBalance(() => null);
    }
  };

  const getAuthUser = async (withoutLoader = false) => {
    !withoutLoader && setIsLoading(true);
    try {
      const response = await getUser();
      setUser(() => ({
        ...response,
        firstName: response.first_name,
        lastName: response.last_name,
      }));
    } catch {
      setUser(() => null);
    }
    !withoutLoader && setIsLoading(() => false);
  };

  const configureNightMode = (isNightMode: boolean) => {
    localStorage.setItem("nightMode", isNightMode ? "true" : "false");
    setNightMode(() => isNightMode);
  };

  /**
   * Edit profile function
   *
   * @param userData
   * @returns {Promise<void>}
   */
  const editProfile = async (userData: UserEditData) => {
    setErrors(() => null);
    try {
      const response = await updateUserProfile(userData);
      await getAuthUser(true);
      return response;
    } catch (error: any) {
      setErrors(() => error.response.data.errors);
    }
    return null;
  };

  /**
   * Remember auth code inside the local storage
   *
   * @param authCode
   */
  const rememberCode = (authCode: string) => {
    localStorage.setItem("authCode", authCode);
  };

  /**
   * Login function
   *
   * @param {UserLoginData} userData The user login data
   * @returns {Promise<void>}
   */
  const login = async (
    userData: UserLoginData,
    redirectTo?: string | null
  ): Promise<void> => {
    try {
      const response = await authenticateUser(
        userData.email,
        userData.password,
        userData.rememberMe,
        userData.vipClient
      );
      rememberCode(response.code);
      setAuthHeader(response.code);

      await getAuthUser();
      navigate(redirectTo || "/");
    } catch (error: any) {
      setErrors(() => error.response.data.errors);
      throw error;
    }
  };

  /**
   * Register function
   *
   * @param {UserLoginData} userData The user login data
   * @returns {Promise<void>}
   */
  const register = async (
    userData: UserRegisterData,
    redirectTo?: string | null
  ): Promise<void> => {
    try {
      const response = await createAccount(
        userData.firstName,
        userData.lastName,
        userData.email,
        userData.password,
        userData.confirmPassword,
        userData.phone
      );

      rememberCode(response.code);
      setAuthHeader(response.code);

      await getAuthUser();
      navigate(redirectTo || "/");
    } catch (error: any) {
      setErrors(() => error.response.data.errors);
      throw error;
    }
  };

  /**
   * Social Login function
   *
   * @param {UserLoginData} userData The user login data
   * @returns {Promise<void>}
   */
  const socialLogin = async (
    provider: "google",
    payload: any,
    redirectTo?: string | null
  ): Promise<void> => {
    try {
      const response = await authenticateUserWithSocial(provider, payload);

      rememberCode(response.code);
      setAuthHeader(response.code);

      await getAuthUser();
      navigate(redirectTo || "/");
    } catch (error: any) {
      setErrors(() => error.response.data.errors);
      throw error;
    }
  };

  /**
   * Logout function
   *
   * @returns {Promise<void>}
   */
  const logout = async (): Promise<void> => {
    // Perform logout logic here and clear the user state
    await logoutUser();
    setUser(null);
    navigate("/");
  };

  /**
   * Check night mode
   *
   * @returns {void}
   */
  const checkNightMode = (): void => {
    const nightMode = localStorage.getItem("nightMode");
    if (nightMode === "true") {
      setNightMode(true);
    }
  };

  /**
   * Sets the AuthCode in the headers of the requests
   * if the user is authenticated
   */
  const setupAuth = () => {
    const authCode = localStorage.getItem("authCode");
    if (authCode) {
      setAuthHeader(authCode);
    }
  };

  const getAuthUserCompany = async () => {
    try {
      const { data } = await getCompanyData();
      setCompany(() => ({
        name: data.name,
        balance: data.balance,
        maxActiveUsers: data.max_active_users,
        activeUsersCount: data.active_users_count,
        isIndividual: data.is_individual,
        configurations: {
          pdfBannerPath: data.configurations.pdf_banner_path || null,
        },
      }));
    } catch {
      setCompany(() => null);
    }
  };

  useEffect(() => {
    getCSRFCookie();
    setupAuth();
    !user && getAuthUser();
    !user && getAuthUserCompany();
    checkNightMode();
  }, []);

  const refreshCompanyData = async () => {
    await getAuthUserCompany();
  };

  const authContextValue = {
    user,
    login,
    errors,
    logout,
    company,
    register,
    nightMode,
    isLoading,
    editProfile,
    socialLogin,
    userBalance,
    getUserBalance,
    refreshCompanyData,
    configureNightMode,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

/**
 * Custom hook to use the authentication context
 *
 * @returns {AuthContextType} The authentication context
 */
export default function useAuthContext() {
  return useContext(AuthContext);
}
